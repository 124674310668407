import request from '@/utils/request';

export async function list(params) {
  // return {
  //     list: [
  //         {
  //             id: '1',
  //             range: ['2021-2-11', '2021-3-11'],
  //             status: 'upcoming'
  //         },
  //         {
  //             id: '2',
  //             range: ['2021-2-11', '2021-3-11'],
  //             status: 'taken'
  //         }
  //     ]
  // }
  // eslint-disable-next-line no-unreachable
  return request({
    url: '/working-days',
    method: 'get',
    params,
  });
}

export function daysOffList(params) {
  return request({
    url: 'days-off',
    params,
  });
}

export async function update(data) {
  return request({
    url: '/working-days',
    method: 'post',
    data,
  });
}

export function add(data) {
  return request({
    url: '/working-days',
    method: 'post',
    data,
  });
}

export function addDaysOff(data) {
  return request({
    url: '/days-off',
    method: 'post',
    data,
  });
}

export function updateDaysOff({ id, ...data }) {
  return request({
    url: `/days-off/${id}`,
    method: 'put',
    data,
  });
}

export function deleteDaysOff({ id }) {
  return request({
    url: `/days-off/${id}`,
    method: 'delete',
  });
}
